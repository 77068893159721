import 'systemjs-webpack-interop/auto-public-path';

import {lifecycle} from './app.js';
import {routes} from './router.js';
import Billing from './pages/Billing.vue';
import {settingRoutes} from '@dnx/core';

// singleSpa & mountParcel also in props
function createManifest() {
  const {name, version, description, dnx} = require('../package.json');
  const defaults = {
    color: '#ff0000',
    icon: 'statistics',
  };

  let manifest = {
    name: name,
    version,
    description,
    ...defaults,
    ...dnx,
  };
  return manifest;
}

const manifest = createManifest();
export default manifest;

const bridge = {
  name: manifest.name,
  bootstrap: async props => {
    const {name, singleSpa} = props;
  },
  mount: async props => {
    const {interop} = props;
    interop?.register(bridge);
  },
  unmount: async props => {
    const {interop} = props;
    interop?.unregister(bridge);
  },
};

settingRoutes.push({
  name: 'billing',
  path: 'billing',
  component: Billing,
  meta: {
    settingType: 'organization',
    translationKey: 'MANGL_BILLING_TITLE',
    permissionId: 'b49d6212-46b0-44aa-b205-56758d84565b',
    //TODO: refactor this
    authState: 'only accessible when authenticated',
  },
});

const core = {
  mount: async props => {
    const {name, mountParcel, customProps} = props;
    for (const [key, value] in customProps) manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel(bridge, {domElement: document.body, interop: Window.interop});

    document.dispatchEvent(
      new CustomEvent('dnx:app-loaded', {
        detail: {
          appmodule: name,
          config: {
            component: Billing,
            routes,
          },
        },
      })
    );
  },
};

export const bootstrap = [lifecycle.bootstrap];

// Mount is called once when active is truthy, after that application should handle route/hashchange/popstate
export const mount = [
  core.mount,
  //DEV: returning mount directly fails mounting. Needs to be fully promisified
  async props => lifecycle.mount,
];
export const unmount = [async props => lifecycle.unmount];
export const update = [core.update, lifecycle.update];
// optional [unloadapplication is called]
export const unload = [lifecycle.unload];

// Overlay in spa-inspector
export const devtools = {
  overlays: {
    selectors: [`.${manifest.name}`],
    options: {
      width: '33vw',
      height: 'auto',
      zIndex: 999,
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#000',
      background: '#ffffff80',
      textBlocks: [manifest.title],
    },
  },
};
