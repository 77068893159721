var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class CallCodes {
    constructor() {
        this.paths = Object.freeze({
            getCallCodes: () => `api/mangl/callcodes`,
            excludeCallCodeFromAnalysis: () => `api/mangl/callcodes/analysisexclusion`,
            includeCallCodeInAnalysis: (callcodenames) => {
                const __query = new QueryStringBuilder().add("CallCodeNames", callcodenames);
                return `api/mangl/callcodes/analysisexclusion${__query.toString()}`;
            },
            setCallCodeAnalysisExclusion: () => `api/mangl/callcodes/analysisexclusion`,
        });
    }
    /**
     * CallCodes.GetCallCodes "GET api/mangl/callcodes"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<CallCodeSummaryModel[]>>}
     */
    getCallCodes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/callcodes`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CallCodes.ExcludeCallCodeFromAnalysis "POST api/mangl/callcodes/analysisexclusion"
     *
     * Exclude all callcodes with the given name from being analyzed MANGL analyses
     * @param {CallCodeAnalysisExclusionSimpleRequest} requestbody
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    excludeCallCodeFromAnalysis(requestbody, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/callcodes/analysisexclusion`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, requestbody);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CallCodes.IncludeCallCodeInAnalysis "DELETE api/mangl/callcodes/analysisexclusion"
     *
     * Mark all callcodes with the given name as being included in MANGL analyses
     * @param {CallCodeAnalysisExclusionSimpleRequest} requestbody
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    includeCallCodeInAnalysis(callcodenames, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("CallCodeNames", callcodenames);
            let path = `api/mangl/callcodes/analysisexclusion${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * CallCodes.SetCallCodeAnalysisExclusion "PUT api/mangl/callcodes/analysisexclusion"
     *
     * Toggle whether all callcodes with the given name are to be excluded from MANGL analyses
     * @param {CallCodeAnalysisExclusionRequest} requestbody
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    setCallCodeAnalysisExclusion(requestbody, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/callcodes/analysisexclusion`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, requestbody);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const callCodes = new CallCodes();
export { callCodes as default, CallCodes };
