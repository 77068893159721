var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class ManglUsage {
    constructor() {
        this.paths = Object.freeze({
            get: (divisionid) => {
                const __query = new QueryStringBuilder().add("divisionId", divisionid);
                return `api/support/mangl/usage${__query.toString()}`;
            },
            getUsageLimits: (divisionid) => {
                const __query = new QueryStringBuilder().add("divisionId", divisionid);
                return `api/support/mangl/usage/limits${__query.toString()}`;
            },
            setUsageLimits: (divisionid) => {
                const __query = new QueryStringBuilder().add("divisionId", divisionid);
                return `api/support/mangl/usage/limits${__query.toString()}`;
            },
            getExpensesWidget: (divisionid, dateformat) => {
                const __query = new QueryStringBuilder().add("divisionId", divisionid).add("dateFormat", dateformat);
                return `api/support/mangl/usage/widget/expenses${__query.toString()}`;
            },
            getUsageWidget: (divisionid, dateformat) => {
                const __query = new QueryStringBuilder().add("divisionId", divisionid).add("dateFormat", dateformat);
                return `api/support/mangl/usage/widget/usage${__query.toString()}`;
            },
        });
    }
    /**
     * ManglUsage.Get "GET api/support/mangl/usage"
     * @param {Guid} divisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UsageModel>>}
     */
    get(divisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("divisionId", divisionid);
            let path = `api/support/mangl/usage${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * ManglUsage.GetUsageLimits "GET api/support/mangl/usage/limits"
     * @param {Guid} divisionid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UsageLimits>>}
     */
    getUsageLimits(divisionid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("divisionId", divisionid);
            let path = `api/support/mangl/usage/limits${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * ManglUsage.SetUsageLimits "PUT api/support/mangl/usage/limits"
     * @param {Guid} divisionid
     * @param {UsageLimits} limits
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UsageLimits>>}
     */
    setUsageLimits(divisionid, limits, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("divisionId", divisionid);
            let path = `api/support/mangl/usage/limits${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, limits);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * ManglUsage.GetExpensesWidget "GET api/support/mangl/usage/widget/expenses"
     * @param {Guid} divisionid
     * @param {string} dateformat
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataResult>>}
     */
    getExpensesWidget(divisionid, dateformat, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("divisionId", divisionid).add("dateFormat", dateformat);
            let path = `api/support/mangl/usage/widget/expenses${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * ManglUsage.GetUsageWidget "GET api/support/mangl/usage/widget/usage"
     * @param {Guid} divisionid
     * @param {string} dateformat
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<WidgetDataResult>>}
     */
    getUsageWidget(divisionid, dateformat, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("divisionId", divisionid).add("dateFormat", dateformat);
            let path = `api/support/mangl/usage/widget/usage${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const manglUsage = new ManglUsage();
export { manglUsage as default, ManglUsage };
