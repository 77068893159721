import {createWebHistory, createRouter} from 'vue-router';
import Billing from './pages/Billing.vue';

export const routes = [
  {
    path: '/',
    name: 'mangl-billing',
    component: Billing,
    meta: {sidebar: true},
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
